<template>
  <div class="admin-cms">
    <PageHeader title="Email Templates" />
    <div class="cms-wrap pt-8">
      <div class="row">
        <EmailTemplate />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/admin/PageHeader";
import EmailTemplate from "@/components/admin/EmailTemplate/index";

export default {
  name: "AdminCMS",

  components: {
    PageHeader,
    EmailTemplate,
  },
};
</script>
